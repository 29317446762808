export const DelayHydration = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/DelayHydration.vue' /* webpackChunkName: "components/delay-hydration" */).then(c => wrapFunctional(c.default || c))
export const HydrationStatus = () => import('../../node_modules/nuxt-delay-hydration/dist/runtime/components/HydrationStatus.vue' /* webpackChunkName: "components/hydration-status" */).then(c => wrapFunctional(c.default || c))
export const CCEmail = () => import('../../components/CCEmail.vue' /* webpackChunkName: "components/c-c-email" */).then(c => wrapFunctional(c.default || c))
export const Consent = () => import('../../components/Consent.vue' /* webpackChunkName: "components/consent" */).then(c => wrapFunctional(c.default || c))
export const LoginViaOtp = () => import('../../components/LoginViaOtp.vue' /* webpackChunkName: "components/login-via-otp" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const ShareUrl = () => import('../../components/ShareUrl.vue' /* webpackChunkName: "components/share-url" */).then(c => wrapFunctional(c.default || c))
export const Tax = () => import('../../components/Tax.vue' /* webpackChunkName: "components/tax" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/Banner/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const BannerSmallBanner = () => import('../../components/Banner/SmallBanner.vue' /* webpackChunkName: "components/banner-small-banner" */).then(c => wrapFunctional(c.default || c))
export const CustomerFeedback = () => import('../../components/Customer/CustomerFeedback.vue' /* webpackChunkName: "components/customer-feedback" */).then(c => wrapFunctional(c.default || c))
export const LoginInputOtp = () => import('../../components/Login/InputOtp.vue' /* webpackChunkName: "components/login-input-otp" */).then(c => wrapFunctional(c.default || c))
export const LoginViaPhone = () => import('../../components/Login/LoginViaPhone.vue' /* webpackChunkName: "components/login-via-phone" */).then(c => wrapFunctional(c.default || c))
export const LoginModalOTP = () => import('../../components/Login/ModalOTP.vue' /* webpackChunkName: "components/login-modal-o-t-p" */).then(c => wrapFunctional(c.default || c))
export const PaymentType = () => import('../../components/Payment/PaymentType.vue' /* webpackChunkName: "components/payment-type" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodAlipay = () => import('../../components/PaymentMethod/Alipay.vue' /* webpackChunkName: "components/payment-method-alipay" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodCreditDebit2C2P = () => import('../../components/PaymentMethod/CreditDebit2C2P.vue' /* webpackChunkName: "components/payment-method-credit-debit2-c2-p" */).then(c => wrapFunctional(c.default || c))
export const PaymentAll = () => import('../../components/PaymentMethod/PaymentAll.vue' /* webpackChunkName: "components/payment-all" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodQRCSCollapse = () => import('../../components/PaymentMethod/QRCSCollapse.vue' /* webpackChunkName: "components/payment-method-q-r-c-s-collapse" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodQRCodeCollapse = () => import('../../components/PaymentMethod/QRCodeCollapse.vue' /* webpackChunkName: "components/payment-method-q-r-code-collapse" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodQrCode = () => import('../../components/PaymentMethod/QrCode.vue' /* webpackChunkName: "components/payment-method-qr-code" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodSCBCollapse = () => import('../../components/PaymentMethod/SCBCollapse.vue' /* webpackChunkName: "components/payment-method-s-c-b-collapse" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodSCBMobileBanking = () => import('../../components/PaymentMethod/SCBMobileBanking.vue' /* webpackChunkName: "components/payment-method-s-c-b-mobile-banking" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodWeChatPay = () => import('../../components/PaymentMethod/WeChatPay.vue' /* webpackChunkName: "components/payment-method-we-chat-pay" */).then(c => wrapFunctional(c.default || c))
export const PaymentSuccess = () => import('../../components/PaymentPage/PaymentSuccess.vue' /* webpackChunkName: "components/payment-success" */).then(c => wrapFunctional(c.default || c))
export const PopupLine = () => import('../../components/Popup/Line.vue' /* webpackChunkName: "components/popup-line" */).then(c => wrapFunctional(c.default || c))
export const PaymentWaitingPayQrCode = () => import('../../components/PaymentWaitingPay/QrCode.vue' /* webpackChunkName: "components/payment-waiting-pay-qr-code" */).then(c => wrapFunctional(c.default || c))
export const PaymentWaitingPaySCBDirectDebit = () => import('../../components/PaymentWaitingPay/SCBDirectDebit.vue' /* webpackChunkName: "components/payment-waiting-pay-s-c-b-direct-debit" */).then(c => wrapFunctional(c.default || c))
export const Product = () => import('../../components/Product/Product.vue' /* webpackChunkName: "components/product" */).then(c => wrapFunctional(c.default || c))
export const ProductCategory = () => import('../../components/Product/ProductCategory.vue' /* webpackChunkName: "components/product-category" */).then(c => wrapFunctional(c.default || c))
export const Promotion = () => import('../../components/Promotion/Promotion.vue' /* webpackChunkName: "components/promotion" */).then(c => wrapFunctional(c.default || c))
export const SampleProduct = () => import('../../components/SampleProduct/SampleProduct.vue' /* webpackChunkName: "components/sample-product" */).then(c => wrapFunctional(c.default || c))
export const SCGPSCGPConsent = () => import('../../components/SCGP/SCGPConsent.vue' /* webpackChunkName: "components/s-c-g-p-s-c-g-p-consent" */).then(c => wrapFunctional(c.default || c))
export const UIAppButton = () => import('../../components/UI/AppButton.vue' /* webpackChunkName: "components/u-i-app-button" */).then(c => wrapFunctional(c.default || c))
export const UIAppControlInput = () => import('../../components/UI/AppControlInput.vue' /* webpackChunkName: "components/u-i-app-control-input" */).then(c => wrapFunctional(c.default || c))
export const UIButtonCheckPrice = () => import('../../components/UI/ButtonCheckPrice.vue' /* webpackChunkName: "components/u-i-button-check-price" */).then(c => wrapFunctional(c.default || c))
export const UINeedHelpBtn = () => import('../../components/UI/need-help-btn.vue' /* webpackChunkName: "components/u-i-need-help-btn" */).then(c => wrapFunctional(c.default || c))
export const UINeedHelpPopup = () => import('../../components/UI/need-help-popup.vue' /* webpackChunkName: "components/u-i-need-help-popup" */).then(c => wrapFunctional(c.default || c))
export const LayoutCategoryMenu = () => import('../../components/layout/category-menu.vue' /* webpackChunkName: "components/layout-category-menu" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooterLine = () => import('../../components/layout/footer-line.vue' /* webpackChunkName: "components/layout-footer-line" */).then(c => wrapFunctional(c.default || c))
export const LayoutFooter = () => import('../../components/layout/footer.vue' /* webpackChunkName: "components/layout-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavbarLine = () => import('../../components/layout/navbar-line.vue' /* webpackChunkName: "components/layout-navbar-line" */).then(c => wrapFunctional(c.default || c))
export const LayoutNavbar = () => import('../../components/layout/navbar.vue' /* webpackChunkName: "components/layout-navbar" */).then(c => wrapFunctional(c.default || c))
export const LayoutNewNavbar = () => import('../../components/layout/new-navbar.vue' /* webpackChunkName: "components/layout-new-navbar" */).then(c => wrapFunctional(c.default || c))
export const ModalConfirmOtp = () => import('../../components/modal/ConfirmOtp.vue' /* webpackChunkName: "components/modal-confirm-otp" */).then(c => wrapFunctional(c.default || c))
export const ModalTemplateBox = () => import('../../components/modal/TemplateBox.vue' /* webpackChunkName: "components/modal-template-box" */).then(c => wrapFunctional(c.default || c))
export const ModalAlert = () => import('../../components/modal/alert.vue' /* webpackChunkName: "components/modal-alert" */).then(c => wrapFunctional(c.default || c))
export const ModalAttributeToolstip = () => import('../../components/modal/attribute_toolstip.vue' /* webpackChunkName: "components/modal-attribute-toolstip" */).then(c => wrapFunctional(c.default || c))
export const ModalAuthen = () => import('../../components/modal/authen.vue' /* webpackChunkName: "components/modal-authen" */).then(c => wrapFunctional(c.default || c))
export const ModalEmail = () => import('../../components/modal/email.vue' /* webpackChunkName: "components/modal-email" */).then(c => wrapFunctional(c.default || c))
export const ModalFactoryExample = () => import('../../components/modal/factoryExample.vue' /* webpackChunkName: "components/modal-factory-example" */).then(c => wrapFunctional(c.default || c))
export const ModalFactoryList = () => import('../../components/modal/factoryList.vue' /* webpackChunkName: "components/modal-factory-list" */).then(c => wrapFunctional(c.default || c))
export const ModalFilters = () => import('../../components/modal/filters.vue' /* webpackChunkName: "components/modal-filters" */).then(c => wrapFunctional(c.default || c))
export const ModalImgSlide = () => import('../../components/modal/imgSlide.vue' /* webpackChunkName: "components/modal-img-slide" */).then(c => wrapFunctional(c.default || c))
export const ModalImgZoom = () => import('../../components/modal/imgZoom.vue' /* webpackChunkName: "components/modal-img-zoom" */).then(c => wrapFunctional(c.default || c))
export const ModalInputAlert = () => import('../../components/modal/input-alert.vue' /* webpackChunkName: "components/modal-input-alert" */).then(c => wrapFunctional(c.default || c))
export const ModalMyDesigns = () => import('../../components/modal/my-designs.vue' /* webpackChunkName: "components/modal-my-designs" */).then(c => wrapFunctional(c.default || c))
export const ModalOtp = () => import('../../components/modal/otp.vue' /* webpackChunkName: "components/modal-otp" */).then(c => wrapFunctional(c.default || c))
export const ModalProductPreview = () => import('../../components/modal/product_preview.vue' /* webpackChunkName: "components/modal-product-preview" */).then(c => wrapFunctional(c.default || c))
export const ModalSampleProductPreview = () => import('../../components/modal/sampleProductPreview.vue' /* webpackChunkName: "components/modal-sample-product-preview" */).then(c => wrapFunctional(c.default || c))
export const OrdersOrderList = () => import('../../components/orders/OrderList.vue' /* webpackChunkName: "components/orders-order-list" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
